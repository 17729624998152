/* Basic Rules */
.switch input { 
    display:none;
}
.switch {
    display:inline-block;
    width:50px;
    height:20px;
    margin:8px;
    /* transform:translateY(50%); */
    position:relative;

    /* display: inline-block;
    width: 50px;
    height: 20px;
    margin: 0px 8px;
    transform: translateY(9%);
    position: relative; */
}
/* Style Wired */
.slider {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    border-radius:30px;
    box-shadow:0 0 0 2px red, 0 0 4px red;
    cursor:pointer;
    border:4px solid transparent;
    overflow:hidden;
     transition:.4s;
}
.slider:before {
    position:absolute;
    content:"";
    width:100%;
    height:100%;
    background:red;
    border-radius:30px;
    transform:translateX(-30px);
    transition:.4s;
}

input:checked + .slider:before {
    transform:translateX(30px);
    background:#cbd62e;
}
input:checked + .slider {
    box-shadow:0 0 0 2px #cbd62e,0 0 2px #cbd62e;
}